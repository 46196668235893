@import url('https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --pri-clr- : #3E0C99;
  --sec-clr- : #EE4364;
  --back-clr- : #F1F5FE;
}

/* Box sizing rules */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Prevent font size inflation */
html {
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

/* Remove default margin in favor of better control in authored CSS */
body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin-block-end: 0;
  margin-block-start: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'], ol[role='list'] {
  list-style: none;
  padding-inline-start: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  line-height: 1.5;
  margin: 0;
}

/* Set shorter line heights on headings and interactive elements */
h1, h2, h3, h4, button, input, label {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
h1, h2, h3, h4 {
  overflow-wrap: break-word;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
  color: inherit;
}

/* Make images easier to work with */
img, picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input, button, textarea, select {
  font: inherit;
}

/* Make sure textareas without a rows attribute are not tiny */
textarea:not([rows]) {
  min-height: 10em;
}

/* Anything that has been anchored to should have extra scroll margin */
:target {
  scroll-margin-block: 5ex;
}