.Gallery {
    padding: 60px 20px;
}

.Gallery h2 {
    font-weight: 800;
    color: var(--pri-clr-);
    margin-bottom: 20px;
    text-align: center;
}

.EventGalleryList {
    padding: 20px;
}