.award-group {
    background-color: #121212;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 50px 0;
}

.award-group img {
    width: 180px
}

.award-item h2 {
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.award-button {
    background-color: #fff;
    color: #B68102;
    padding: 10px 20px;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 10px;
}

